<template lang="pug">
div.back-circle(@click="click")
  .arrow
</template>

<script>
export default {
  name: "BackCircle",
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="sass">
.arrow
  background: #000000
  height: 1px
  width: 20px
  margin: 0 auto
  position: relative
  cursor: pointer
.arrow:before
  left: -3px
  bottom: -3px
  transform: rotate(45deg)

.arrow:after
  left: -3px
  top: -3px
  transform: rotate(-45deg)

.arrow:before, .arrow:after
  content: ""
  background: #000000
  position: absolute
  height: 1px
  width: 10px
</style>